<template>
  <div class="sub_nav_box flex_bw_start" :class="[bigBg && 'big_bg',isShow?'big_bgCv':'']">
    <div class="title">{{ title }}</div>
    <div class="title_en">{{ titleEn }}</div>
  </div>
</template>
  <script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleEn: {
      type: String,
      default: ''
    },
    bigBg: {
      type: Boolean,
      default: false
    },
    isShow:{
      type: Boolean,
      default: false
    },
    roundBg: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    changeTab(item, index) {
      this.active = index;
      this.$emit('click', item);
    }
  }
};
</script>
  <style lang="scss" scoped>
// 副标题样式
.sub_nav_box {
  height: 79rem;
  width: 100%;
  line-height: 60rem;
  padding-left: 53rem;
  padding-right: 42rem;
  background: url('@/assets/img/scenery/sub_bg.png') no-repeat;
  background-size: contain;
  color: #fff;
  font-family: hanzhenguangbiao;
  .title {
    font-size: 30rem;
  }
  .title_en {
    font-size: 22rem;
    color: #70B7A4;

  }
}
.big_bg {
  background: url('@/assets/img/tourist/big_bg.png') no-repeat;
  background-size: 100% 100%;
}
.big_bgCv {
  background: url('@/assets/img/tourist/big_bgCv.png') no-repeat ;
  background-size: 100% 100%;
}
</style>
  