<template>
  <div id="app">
     <router-view />
     
  </div>
</template>
<script>
/* eslint-disable */
//这里用provide的方式引入 是作为全局变量

export default {

  name: "App",

  data() {
    return {
   
      clientWidth: 1920,
      
    };
  },

  mounted() {

    // 记录一开始的屏幕宽度
    this.handleSize();
    window.addEventListener("resize", () => {
      this.handleSize();
    });
  },
  methods: {
    handleSize() {
      let docEl = document.documentElement;
      let clientWidth = docEl.clientWidth;

      if (!clientWidth) return;
      docEl.style.fontSize = 1 * (clientWidth / 3096) + "px";
    },
  },
};
</script>
<style>
@import url("./styles/body.css");
@import url("./styles/common.css");

#app {
  width: 100vw;
  height: 100vh;
  background-color: #00122c;
  background-size: cover;
  overflow: hidden;
  color: #fff;
  font-size: 16rem;
  /* min-width: 1200px; */
}
/* 去除地图logo */
.amap-logo,.amap-copyright{
  display: none !important;
}
</style>
<style lang="scss"></style>
