/**
 * token操作封装
 */
const TOKEN_STORE_NAME = 'access_token';

/**
 * 获取缓存的token
 * @returns {string}
 */
export function getToken() {
  const token = localStorage.getItem(TOKEN_STORE_NAME);
  if (!token) {
    return sessionStorage.getItem(TOKEN_STORE_NAME);
  }
  return token;
}

/**
 * 缓存token
 * @param token token
 * @param remember 是否永久存储
 */
export function setToken(token) {
  removeToken();
  if (token) {
    localStorage.setItem(TOKEN_STORE_NAME, token);
  }
}

/**
 * 移除缓存的token
 */
export function removeToken() {
  localStorage.removeItem(TOKEN_STORE_NAME);
  sessionStorage.removeItem(TOKEN_STORE_NAME);
}
