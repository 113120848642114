/**
 * axios 实例
 */
import axios from 'axios';
import router from '@/router';
import { Message, MessageBox, Loading } from 'element-ui';
import { getToken, setToken, removeToken } from '../utils/token-util';
import qs from 'qs';
let loadingInstance;

const service = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  timeout: 600000
});

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
  (config) => {
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data);
    }

    if (config.loading) {
      loadingInstance = Loading.service();
    }
    if (config.method !== 'get' && config.showLoading !== false) {
      loadingInstance = Loading.service();
    }

    // 添加 token 到 header
    let token = getToken();
    console.log(token, 'token获取');
    if (token && config.headers) {
      config.headers['Authorization'] = token;
      // console.log(config);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 */
service.interceptors.response.use(
  (res) => {
    if (loadingInstance) loadingInstance.close();
    if (res.config.url.indexOf('export') !== -1) {
      return res;
    }
    if (res.config.isFile) {
      return res;
    }
    // 登录过期处理
    if (res.data?.code === 2) {
      Message,
        MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
          confirmButtonText: '重新登录',
          callback: (action) => {
            if (action === 'confirm') {
              removeToken();

              if (window.location.href.indexOf('login') == -1) {
                router.push('/login');
              }
            }
          },
          beforeClose: () => {
            Message, MessageBox.close();
          }
        });

      return Promise.reject(new Error(res.data.msg));
    } else if (res.data.code !== 0) {
      console.log('ooooooooooo', res.data.msg);
      Message.error(res.data.msg);
      return Promise.reject(new Error(res.data.msg));
    }
    // token 自动续期
    let token = res.headers.token;
    if (token) {
      setToken(token);
    }
    if (res.config.deep) {
      return res.data;
    }
    return res.data;
  },
  (error) => {
    if (loadingInstance) loadingInstance.close();
    Message.error('网络请求异常，请稍后重试!');
    return Promise.reject(error);
  }
);

export default service;
