/* eslint-disable */
import Vue from 'vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './styles/index.scss';
import mitt from 'mitt';
import request from '@/utils/request';
import subtitle from './components/subtitle.vue';
import subnav from './components/subNav.vue';
import ws from '@/websocket/websocket.js';
import 'animate.css';
import * as echarts from 'echarts'; //引入echart
import 'echarts-liquidfill';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
window.videojs = videojs;
require('../node_modules/video.js/dist/lang/zh-CN');
// 引入 EleAdmin 语言包
// import defaultLang from "ele-admin/es/lang/zh-CN";
import locale from 'element-ui/lib/locale/lang/zh-CN.js';
// 在安装 ElementUI 时设置 locale
Vue.use(ElementUI, {
  locale: locale,
  size: 'medium'
});
Vue.config.productionTip = false;
Vue.prototype.$request = request;
Vue.prototype.$echarts = echarts;
Vue.prototype.$WEBSOCKET = ws;
Vue.component('subtitle', subtitle); //副标题组件
Vue.component('subnav', subnav); //副标题组件
Vue.prototype.$bus = mitt();

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
