<template>
  <div class="subtitle_box flex_bw_start">
    <span>{{ title }}</span>
    <!-- 右侧筛选条件 -->
    <ul class="flex_end_center">
      <li
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(item, index)"
        :class="[active === index && 'active']"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    changeTab(item, index) {
      this.active = index;
      this.$emit("click", item);
    },
  },
};
</script>
<style lang="scss" scoped>
// 副标题样式
.subtitle_box {
  height: 79rem;
  padding-left: 56rem;
  background-image: url("@/assets/img/title_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  line-height: 60rem;
  span {
    font-family: hanzhenguangbiao;
    font-size: 26rem;
    font-weight: 400;
    color: #ffffff;

    background: linear-gradient(
      0deg,
      #79c5ff 0%,
      #ffffff 59.8388671875%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ul {
    margin-top: 15rem;
  }
  li {
    width: 80rem;
    height: 36rem;
    margin-left: 9rem;
    background: rgba(8, 148, 255, 0);
    border: 1px solid #1d80da;
    font-size: 16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #c3e1ff;
    text-align: center;
    line-height: 36rem;
  }
  .active {
    background: rgba(8, 148, 255, 0.3);
    color: #ffffff;
  }
}
</style>
