import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    alias: '/login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/menu-blue',
    name: 'home',
    component: () => import('../views/newHome.vue'),
    // redirect: '/menu/menu_industry', //重定向到第一个子路由
    children: [
      // 山里故事
      {
        path: 'story',
        component: () => import('../views/newMenu/story.vue')
      },
      // 田园风光
      {
        path: 'tourist',
        component: () => import('../views/newMenu/tourist.vue')
      }
      // // 文旅产业
      // {
      //   path: 'menu_industry',
      //   component: () => import('../views/menu/menu_industry.vue')
      // },
      // // 智慧农业
      // {
      //   path: 'menu_pick',
      //   component: () => import('../views/menu/menu_pick.vue')
      // },
      // {
      //   path: 'menu_area',
      //   component: () => import('../views/menu/menu_area.vue')
      // }
    ]
  },
  {
    path: '/menu',
    name: 'home',
    component: () => import('../views/home-blue.vue'),
    // redirect: '/menu/menu_industry', //重定向到第一个子路由
    children: [
      {
        path: 'story',
        component: () => import('../views/newMenu_blue/story.vue')
      },
      {
        path: 'tourist',
        component: () => import('../views/newMenu_blue/tourist.vue')
      },
      {
        path: 'flow',
        component: () => import('../views/newMenu_blue/passengerflow.vue')
      }
    ]
  },
  {
    path: '/menu-three',
    name: 'home',
    component: () => import('../views/home_three.vue'),
    // redirect: '/menu/menu_industry', //重定向到第一个子路由
    children: [
      {
        path: 'story',
        component: () => import('../views/newMenu_three/story.vue')
      },
      {
        path: 'tourist',
        component: () => import('../views/newMenu_three/tourist.vue')
      }
    ]
  },
  {
    path: '/menu-old',
    name: 'home',
    component: () => import('../views/home.vue'),
    // redirect: '/menu/menu_industry', //重定向到第一个子路由
    children: [
      {
        path: 'menu_area',
        component: () => import('../views/menu/menu_area.vue')
      },
      {
        path: 'menu_industry',
        component: () => import('../views/menu/menu_industry.vue')
      },
      {
        path: 'menu_pick',
        component: () => import('../views/menu/menu_pick.vue')
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
