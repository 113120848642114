// 定义 websocket
export default {
  websocket: {},
  setWebsocket: function (newWs) {
    this.websocket = newWs;
  },

  message: {},

  reConnectTimeOutObj: null, //断线重连后，
  reConnectTimeOut: 10000, //延迟10秒重新创建WebSocket连接
  reConnetCount: 0,
  isConnect: false, //避免重复连接
  heartTimeOutObj: null, //心跳发送对象
  heartTimeOut: 60000, //心跳60s发送一次
  heartMessage: 'aaa' //心跳发送内容
};
